exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/AboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-project-list-tsx": () => import("./../../../src/pages/ProjectList.tsx" /* webpackChunkName: "component---src-pages-project-list-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/Projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-services-aggregate-testin-tsx": () => import("./../../../src/pages/services/AggregateTestin.tsx" /* webpackChunkName: "component---src-pages-services-aggregate-testin-tsx" */),
  "component---src-pages-services-aggregates-hover-cards-tsx": () => import("./../../../src/pages/services/AggregatesHoverCards.tsx" /* webpackChunkName: "component---src-pages-services-aggregates-hover-cards-tsx" */),
  "component---src-pages-services-concrete-hover-cards-tsx": () => import("./../../../src/pages/services/ConcreteHoverCards.tsx" /* webpackChunkName: "component---src-pages-services-concrete-hover-cards-tsx" */),
  "component---src-pages-services-concrete-testing-tsx": () => import("./../../../src/pages/services/ConcreteTesting.tsx" /* webpackChunkName: "component---src-pages-services-concrete-testing-tsx" */),
  "component---src-pages-services-soil-hover-cards-tsx": () => import("./../../../src/pages/services/SoilHoverCards.tsx" /* webpackChunkName: "component---src-pages-services-soil-hover-cards-tsx" */),
  "component---src-pages-services-soil-testing-tsx": () => import("./../../../src/pages/services/SoilTesting.tsx" /* webpackChunkName: "component---src-pages-services-soil-testing-tsx" */)
}

